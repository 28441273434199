import { useRouter } from 'next/router'
import React, { useState } from 'react'

function SidebarMenus({ menus }) {

    const router = useRouter();
    const [showSubMenu, setShowSubMenu] = useState(false);

    return (
        <div className='flex flex-col gap-2'>
            {
                menus.map((menu, index) => {
                    return (
                        <div key={index}>
                            <div
                                className={`cursor-pointer flex items-center pl-8 text-sm h-10 rounded-md ${menu.active ? 'bg-primary-900' : ''}`}
                                onClick={
                                    () => {
                                        menu?.subMenus ? 
                                            setShowSubMenu(!showSubMenu)
                                        : router.push(menu.url)
                                    }
                                }
                            >
                                {
                                    menu?.icon && <IcomoonIcon icon={menu?.icon} size={menu.iconSize} />
                                }
                                {menu.title}
                            </div>
                            {
                                (menu?.subMenus && showSubMenu) && (
                                    <div className='flex flex-col gap-3 subMenu pl-12'>
                                        {
                                            menu.subMenus.map((subMenu, index) => {
                                                return (
                                                    <div key={index} className='text-sm'>
                                                        {subMenu.title}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default SidebarMenus