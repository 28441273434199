export const menuItems = [
    {
        title: "Customers",
        url: "/customers",
        active: false,
    },
    {
        title: "Merchants",
        url: "/merchants",
        active: false,
    },
    {
        title: "Transactions",
        url: "/transactions",
        active: false,
    },
    {
        title: "Referrals",
        url: "/referrals",
        active: false,
    },
    {
        title: "Settings",
        url: "/settings",
        active: false,
    },
     {
        title: "Landing Page",
        url: "/landing-page",
        active: false,
    }
];

export const otherBannerBreadCrums = (otherBannerType, isEdit, bannerDetails, showBanner)=>{
   if(otherBannerType === 'Footer Banner'){
    return [
        {
            isActive: false,
            name: "Kashkounter",
            path: "/customers",
        },
        {
            isActive: false,
            name: `Landing Page`,
            path: `/landing-page`
        },
        {
            isActive: false,
            name: `${showBanner} Banners`,
            path: `/landing-page/otherBanner`
        },
        {
            isActive: true,
            name: `${isEdit ? "Edit" : "View"} - ${otherBannerType}`,
            path: `/landing-page/view/${bannerDetails.id}`,
        }
    ];
   }else if(otherBannerType === 'Mid Banner'){
      return [
        {
            isActive: false,
            name: "Kashkounter",
            path: "/customers",
        },
        {
            isActive: false,
            name: `Landing Page`,
            path: `/landing-page`
        },
        {
            isActive: false,
            name: `${showBanner} Banners`,
            path: `/landing-page/otherBanner`
        },
        {
            isActive: true,
            name: `${isEdit ? "Edit" : "View"} - ${otherBannerType}`,
            path: `/landing-page/view/${bannerDetails.id}`,
        }
    ];
   }else{
    return  [
        {
            isActive: false,
            name: "Kashkounter",
            path: "/customers",
        },
        {
            isActive: false,
            name: `Landing Page`,
            path: `/landing-page`
        },
        {
            isActive: false,
            name: `${showBanner} Banner`,
            path: `/landing-page/otherBanner`
        },
        {
            isActive: true,
            name: `${isEdit ? "Edit" : "View"} - ${showBanner} Banner`,
            path: `/landing-page/view/${bannerDetails.id}`,
        }
    ];
   }
}

export const getTabs = (isMerchant, id) => {
      if(isMerchant === 'isBanner'){
         return   [{
            id: 1,
            label: "Hero Banners",
            active: false,
            url: `/landing-page`,
        },
        {
            id: 2,
            label: "Other Banners",
            active: false,
            url: `/landing-page/otherBanner`,
        }
    ]
      }else if(isMerchant){
        return [
            {
                id: 1,
                label: "Merchant Details",
                active: false,
                url: `/merchants/${id}`,
            },
            {
                id: 2,
                label: "Categories",
                active: false,
                url: `/merchants/${id}/categories`,
            },
            {
                id: 3,
                label: "Coupons",
                active: false,
                url: `/merchants/${id}/coupons`,
            },
            {
                id: 4,
                label: "Banners",
                active: false,
                url: `/merchants/${id}/banners`,
            },
        ]
    }else{
        return [{
                id: 1,
                label: "Set Minimum Wallet Withdrawal Limit",
                active: false,
                url: "/settings/withdrawal",
            },
           {
            id: 2,
            label: "Set Conversion Rate",
            active: false,
            url: `/settings/conversionRate`,
        }
        ]
    }   
};

export const transactionStatus = {
    All: [],
    "Order Created": ["on hold", "pending", "created"],
    "Order Delivered": ["delivered", "confirmed but delayed"],
    "Cashback Credited": ["confirmed", "credited"],
    "Order Cancelled": ["declined"],
};
