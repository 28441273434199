import React from "react";
import Icon from "../atoms/Icon";
import { useRouter } from "next/router";
import SidebarMenus from "../molecules/SidebarMenus";

const AdminSidebar = ({
  menuItems,
  linkColor,
  linkHoverColor,
  activeLinkColor,
  linkHoverBgColor,
  ...property
}) => {
                                                                          
  const router = useRouter();
  const activeMenuTab = menuItems.map((item) => Object.assign(item, {active:router.pathname.includes(item.url.split('/')[1])}));

  return (
    <div
      className={`w-[240px] flex-none bg-secondary-900 text-white h-screen Adminsidebar ${property.className}`}
    >
      <div className="text-white">
        <div className="px-3 py-3.5 shadow flex justify-center items-center">
          <Icon
            iconSrc="/images/KashKounter.png"
            iconWidth={184}
            iconHeight={52}
          />
        </div>
        <div className="px-8 py-6">
          <SidebarMenus 
            menus={activeMenuTab}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
