import Cookies from "universal-cookie";

const cookies = new Cookies();

export const getAuth = () => {
    const auth = cookies.get("AUTH");

    return auth;
};
export const setAuth = (authObject) => {
    cookies.set("AUTH", JSON.stringify(authObject), { path: "/" });
    return authObject;
};

export const removeAuth = () => {
    return cookies.remove("AUTH", { path: "/" });
};

export const isAuthenticated = (user) => user != null && user.token;

export const isUnauthorizedRequest = (auth, token) => {
    return !auth || !isAuthenticated(JSON.parse(auth), token);
};
