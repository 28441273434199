import EmptyLayout from "./EmptyLayout";
import AdminLayout from "./AdminLayout";

const LayoutConfig = [
    {
        path: "/customers",
        Layout: AdminLayout,
    },
    {
        path: "/success",
        Layout: EmptyLayout,
    },
    {
        path: "/merchants",
        Layout: AdminLayout,
    },
    {
        path: "/transactions",
        Layout: AdminLayout
    },
    {
        path: "/settings",
        Layout: AdminLayout
    },
    {
        path: "/landing-page",
        Layout: AdminLayout
    },
    {
        path: "/referrals",
        Layout: AdminLayout
    },
    {
        path: "/",
        Layout: EmptyLayout,
    },
    {
        path: "",
        Layout: EmptyLayout,
    },
];

const getLayoutConfig = (path) => {
    let config = LayoutConfig.find((configItem) => path.includes(configItem.path));
    if (config) return config;
    else return { Layout: EmptyLayout };
};

export { getLayoutConfig };
