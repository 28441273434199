import useClickOutside from "../components/common/ClickOutside";
import Avatar from "../atoms/Avatar";
import IcomoonIcon from "../atoms/IcomoonIcon";
import Text from "../atoms/Text";
import { useRef, useState } from "react";
import { useRouter } from "next/router";

const AdminTopBar = ({ logoutHandler, username, avatar, alwaysOpen }) => {
  const [profile, setProfile] = useState(false);

  const handleProfile = () => {
    setProfile(!profile);
  };

  const close = () => {
    setProfile(false);
  };

  const dialogRef = useRef(null);
  if (!alwaysOpen) useClickOutside(dialogRef, () => close());

  const router = useRouter();

  const getPathNameWithoutHyphen = (router) => {
    let path = router.pathname.split("/")[1];
    return path.replace(/-/g, " ");
};
  return (
    <div className="bg-white">
      <div className="flex justify-between px-6 py-5 shadow items-center">
        <Text
          variant="bodyLarge"
          className="text-neutral-800 capitalize"
          fontWeight="font-semibold"
        >
          {getPathNameWithoutHyphen(router)}
        </Text>

        <div
          className="flex items-center space-x-3 cursor-pointer relative"
          onClick={handleProfile}
          ref={dialogRef}
        >
          {profile && (
            <div className="px-6 py-3 bg-white shadow-md border border-neutral-100 rounded-lg w-fit absolute z-[62] right-1 top-12 hidden lg:flex lg:flex-col min-w-[160px]">
              <div
                className="cursor-pointer text-neutral-500 hover:text-primary-900 transition-all ease-in-out duration-700 text-sm md:text-base font-medium"
                onClick={() => {
                  logoutHandler();
                  setProfile(false);
                }}
              >
                Logout
              </div>
            </div>
          )}
          <Avatar size="large" imgSrc={avatar} />
          <div className="">
            <Text variant="bodySmall" className="text-neutral-900">
              {username}
            </Text>
            <Text variant="" className="text-neutral-500" fontSize="text-xs">
              Admin
            </Text>
          </div>
          <IcomoonIcon
            icon={"arrow-right"}
            className={`transition-all ${profile ? "-rotate-90" : "rotate-90"}`}
            size={"16"}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminTopBar;
