import React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { getAuth, removeAuth } from "../../services/identity.service";
import { useState, useEffect } from "react";
import AdminSidebar from "../../organisms/AdminSidebar";
import AdminTopBar from "../../organisms/AdminTopBar";
import { menuItems } from "../../helpers/constants";

const AdminLayout = ({ children }) => {
  const router = useRouter();
  const [domLoaded, setDomLoaded] = useState(false);

  const [auth, setAuth] = useState({});

  useEffect(() => {
    setDomLoaded(true);
    const auth = getAuth();
    setAuth(auth);
  }, []);

  const logoutHandler = () => {
    removeAuth();
    router.reload("/");
  };

  const username = auth?.name;
  const avatar = auth?.profilePic;

  return (
    <div className="flex">
      {domLoaded && (
        <AdminSidebar
          activeLinkColor="bg-primary-900 text-white rounded-md"
          linkColor=""
          linkHoverBgColor="bg-primary-100"
          linkHoverColor="text-white"
          menuItems={menuItems}
        />
      )}
      <div className="flex-grow h-screen overflow-hidden">
        <AdminTopBar
          avatar={avatar}
          username={username}
          logoutHandler={logoutHandler}
        />
        <div className="relative py-5 px-6 h-[calc(100vh-80px)] flex flex-col overflow-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node,
};

export default AdminLayout;
